.pagination {
    display: flex;
    justify-content: end;
    list-style: none;
    padding: 0;
}

.pagination li {
    margin-right: 10px;
}

.pagination li:last-child{
    margin-right: 0;
}

.pagination a {
    display: inline-block;
    padding: 8px 12px;
    background-color: #f5f5f5;
    color: #333;
    border: 1px solid #ccc;
    text-decoration: none;
    border-radius: 4px;
}



.pagination .selected a{
    background-color: #428bca;
    color: #fff;
    border-color: #428bca;
}

.pagination .disabled a {
    opacity: 0.5;
    pointer-events: none;
}