.loading-indicator {
    text-align: center;
  }
  
  .loading-bullet {
    display: inline-block;
    opacity: 0;
    font-size: 2em;
    color: #02a17c;
  }
  
  /* CSS Animations */
  @-webkit-keyframes fadeInRTL1 {
    0% {
      opacity: 0;
      -webkit-transform: translateX(20px);
      transform: translateX(20px);
    }
    30% {
      opacity: 1;
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
    }
    60% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
  }
  
  @keyframes fadeInRTL1 {
    0% {
      opacity: 0;
      -webkit-transform: translateX(20px);
      transform: translateX(20px);
    }
    30% {
      opacity: 1;
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
    }
    60% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
  }
  
  @-webkit-keyframes fadeInRTL2 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
      -webkit-transform: translateX(20px);
      transform: translateX(20px);
    }
    40% {
      opacity: 1;
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
    }
    60% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
  }
  
  @keyframes fadeInRTL2 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
      -webkit-transform: translateX(20px);
      transform: translateX(20px);
    }
    40% {
      opacity: 1;
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
    }
    60% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
  }
  
  @-webkit-keyframes fadeInRTL3 {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
      -webkit-transform: translateX(20px);
      transform: translateX(20px);
    }
    50% {
      opacity: 1;
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
    }
    60% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
  }
  
  @keyframes fadeInRTL3 {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
      -webkit-transform: translateX(20px);
      transform: translateX(20px);
    }
    50% {
      opacity: 1;
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
    }
    60% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
  }
  
  .loading-bullet {
    display: inline-block;
    opacity: 0;
    -webkit-animation: 3s ease 0.5s infinite fadeInRTL1;
    animation: 3s ease 0.5s infinite fadeInRTL1;
  }
  
  .loading-bullet + .loading-bullet {
    -webkit-animation-name: fadeInRTL2;
    animation-name: fadeInRTL2;
  }
  
  .loading-bullet + .loading-bullet + .loading-bullet {
    -webkit-animation-name: fadeInRTL3;
    animation-name: fadeInRTL3;
  }