.spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 400px;
    right: 550px;
  }

  .form-control.disabled {
    color: black;
  }

  
  
  