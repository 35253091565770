.no-border .react-select__control {
    border: none;
  }
  
  .no-border .react-select__menu {
    border: none;
  }
  
  .no-border .react-select__value-container {
    border: none;
  }
  