.block-ui {
    position: relative;
  }
  
  .block-ui-container {
    position: absolute;
    z-index: 1010;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    min-height: 2em;
    cursor: wait;
    overflow: hidden;
  }
  
  .block-ui-container:focus {
    outline: none;
  }
  
  .block-ui-overlay {
    position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  }
  
  .block-ui-message-container {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    z-index: 10001;
  }
  
  .block-ui-message {
    color: #333;
    background: none;
    z-index: 1011;
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }